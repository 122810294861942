<template>
  <div class="content">
    <div class="header">
      <div class="title">Plastic Free Admin System</div>
      <div class="des">Backend Portal</div>
    </div>
    <div class="security-box">
      <img src="@/assets/images/security-logo.png" />
      <div class="text">Login</div>
      <el-form label-position="top"  :rules="rules" ref="ruleForm" label-width="80px" :model="form">
        <el-form-item>
          <el-input v-model="form.username" :placeholder="'Username'"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input show-password v-model="form.password" :placeholder="'Password'"></el-input>
        </el-form-item>
        <el-button @click="login">Login</el-button>
      </el-form>
    </div>
    <div class="footer"></div>
    <loadingDialog :center-dialog-visible="centerDialogVisible"></loadingDialog>
  </div>
</template>

<script>
import { login } from "@/api/security";
import { setToken, setUserInfo } from "@/utils/auth";
import loadingDialog from "@/components/loadingDialog/index";
// import uuidv1 from 'uuid/v1' 
/**
 * [
    'name'       => 'admin',
    'role_id'    => 1,
    'email'      => "admin@test.com",
    'password'   => 'admin123456',
    'created_at' => now(),。
    'updated_at' => now(),
],
[
    'name'       => 'eatery group staff',
    'role_id'    => 2,
    'email'      => "group@test.com",
    'password'   => 'group123456',
    'created_at' => now(),
    'updated_at' => now(),
],
[
    'name'       => 'eatery staff',
    'role_id'    => 3,
    'email'      => "eatery@test.com",
    'password'   => 'eatery123456',
    'created_at' => now(),
    'updated_at' => now(),
],
 */
export default {
  components: { loadingDialog },
  data() {
    return {
      centerDialogVisible: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
         
      },
    };
  },
  created(){
    // console.log(uuidv1())
  },
  methods: {
    login() {
      this.$router.push({ path: "/eatery" });
      this.centerDialogVisible = true;
      let form = {
        client: '',
        email: this.form.username,
        password: this.form.password,
        device_id: 'test'
      };
      login(form).then((v) => {
        this.centerDialogVisible = false;
        setToken(v.data.accessToken);
        setUserInfo('userInfo', v.data)
        this.$store.dispatch("setAdminUserInfo", v.data);
        if(v.data.position == 3){
          this.$router.push({ path: `/eatery/edit/${v.data.eateryId}` });
        } else {
          this.$router.push({ path: "/" });
        }
      })
      .catch((error) => {
        if(error.response.status === 500) {
            this.$message.error(error.response.data.message);
        } else if (error.response) {
          this.$message.error(error.response.data.error_message);
        }
      })
    },
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  background-color: #F1F8F9;
  font-family: Inter;
  position: relative;
  overflow: hidden;
}
.header{
  text-align: center;
  font-weight: 700;
  .title{
    color:#1B7379;
    font-size: 2.5rem;
    line-height: 1.25;
    margin-bottom:15px;
  }
  .des{
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
}
.security-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  border-radius: 1.875rem;
  background: #FFF;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.15);
  padding:70px 180px 40px;
  margin-top:35px;
  z-index: 1;
  img{
    width:171px;
  }
  .text{
    margin:44px 0 40px;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2rem;
  }
}
.el-form {
  width: 360px;
  .el-button {
    width: 100%;
    background-color:  #1B7379;
    border:none;
    border-radius: 50px;
    color:#FFF;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    font-family: Noto Sans HK;
    height:50px;
    padding:10 auto;
    margin-bottom:8px;
  }
  .forgot{
    text-align: center;
    color: #1B7379;
    font-family: Noto Sans HK;
    line-height: 1.5;
  }
}
.footer{
  position: absolute;
  bottom: 0;
  left:-25vw;
  height: 37.1vh;
  width: 150%;
  border-top-left-radius:100%;
  border-top-right-radius:100%;
  border-top: none;
  background-color: #DEF5F6;
}
</style>